.city-component {
	height: 12rem;
	border: none;
	cursor: pointer !important;
}

.city-component .text-container {
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0.1),
		rgba(0, 0, 0, 0.1)
	);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	color: #ffffff;
	text-shadow: 0 0 1rem #000000;
	height: 100%;
	width: 100%;
	top: 0;
}

.city-component img {
	background-color: rgba(var(--primary-color), 0.1);
	background-image: url('./../../assets/images/logos/logo.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;

	filter: grayscale(0);
	transition: filter 500ms;
}

.city-component:hover img {
	filter: grayscale(100%);
}

.city-component .text-container .title {
	font-size: 2rem;
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
