#home-page .banner-area {
	height: 100vh;
	background-color: rgba(var(--primary-color), 0.1);
	background-image: var(--bg-image), url('./../../assets/images/logos/logo.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover, contain;
}

/* Banner Overlay */
#home-page .banner-area::before {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	background: #004e98;
	mix-blend-mode: multiply;
	opacity: 0.8;
	z-index: 1;
}

/* Hero Section */
#home-page .hero-section {
	justify-content: start !important;
}

@media only screen and (max-width: 768px) {
	#home-page .hero-section {
		justify-content: center !important;
	}
}

/* Courses Filter */
#home-page .courses-filter .nav-link {
	background: #fbfcff;
	border: 1px solid #e5f1fb;
	border-radius: 6px;
	height: 44px;
	width: fit-content;
	line-height: 44px;
	padding: 0 25px;
	font-weight: 600;
	font-size: 14px;
	color: #808191;
	margin-right: 10px;
	margin-top: 10px;
	user-select: none;

	transition: background-color 500ms;
}

#home-page .courses-filter .nav-link:hover {
	background-color: #e5f1fb;
}

#home-page .courses-filter .nav-link.active {
	background: #e5f1fb;
	color: rgba(var(--main-color), 1);
}

/* Certificates */
#home-page .certificates .certificate-image {
	filter: grayscale(100%);

	transition: filter 500ms;
}

#home-page .certificates .certificate-image:hover {
	filter: grayscale(0);
}

/* Slider Icons */
#courses-filter-results .swiper-button-next,
#courses-filter-results .swiper-button-prev {
	display: none !important;
}

.courses-filter .swiper-button-next,
.courses-filter .swiper-button-prev {
	top: 50% !important;
	left: unset !important;
	right: unset !important;
	transform: translate(0, 2px) !important;
}

.courses-filter .swiper-button-prev {
	left: 0 !important;
}

.courses-filter .swiper-button-next {
	right: 0 !important;
}

.courses-filter .swiper-slide.nav-link {
	font-size: 14px !important;
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
