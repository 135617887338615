.category-card {
	border: none !important;
	box-shadow: 0px 6px 15px rgb(0 0 0 / 5%);
}

.category-card .card-img-top {
	--bs-card-spacer-x: 0;

	background-color: rgba(var(--primary-color), 0.1);
	background-image: url('./../../assets/images/logos/logo.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.category-card .category-card-title {
	color: rgba(var(--heading-color), 1) !important;

	transition: color 0.5s;
}

.category-card:hover .category-card-title {
	color: rgba(var(--main-color), 1) !important;
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
