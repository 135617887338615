#footer {
	position: relative;
	background-color: #f9fafd;
	border-top: 1px solid #e5f1fb;
	background-image: var(--bg-image), url('./../../assets/images/logos/logo.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover, contain;
}

/* Banner Overlay */
#footer::before {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	background: #004e98;
	mix-blend-mode: multiply;
	opacity: 0.8;
	z-index: 1;
}

#footer .footer-title {
	position: relative;
	width: fit-content;

	transition: color 350ms;
}

#footer .footer-title:hover {
	color: rgba(var(--primary-color), 1);
	cursor: default;
}

#footer .footer-title::before {
	content: '';
	position: absolute;
	bottom: -4px;
	left: 8px;
	width: 35%;
	height: 3px;
	background-color: rgba(var(--primary-color), 1);

	transition: width 350ms;
}

#footer .footer-title:hover::before {
	width: 100%;
}

#footer ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

#footer li::before {
	display: none !important;
}

#footer li {
	color: #ffffff;
	padding: 0 0 0.25rem 0;

	transition: padding-left 500ms, color 500ms;
}

#footer li:hover {
	color: rgba(var(--primary-color), 1);
	padding-left: 0.5rem;
}

#footer li.phone-container:hover {
	padding-left: 0;
}

#footer a {
	color: #ffffff;
	text-decoration: none;

	transition: color 250ms;
}

#footer a:hover {
	color: rgba(var(--primary-color), 1) !important;
}

#footer .phone-number {
	direction: ltr !important;
}

#footer .phone-number:not(:last-of-type) {
	padding-bottom: 0.5rem;
}

/* Social Media Accounts */
.list-group-item {
	border: none !important;
	border-radius: 0.5rem !important;
	background-color: transparent !important;
}

.list-group-item:not(:last-of-type) {
	margin-right: 0.5rem;
}

.social-accounts * {
	color: #ffffff;

	transition: background-color 250ms, color 250ms;
}

.social-accounts > *:hover {
	background-color: #ffeaeb !important;
}

.social-accounts > *:hover * {
	color: rgba(var(--primary-color), 1) !important;
}

.company-signature a {
	color: #fff;
	text-decoration: none;
	font-weight: 600;

	transition: color 350ms;
}

.company-signature a:hover,
.company-signature a:focus {
	color: rgba(var(--primary-color), 1);
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
#footer[lang='ar'] .footer-title::before {
	left: unset;
	right: 8px;
}

#footer[lang='ar'] li {
	transition: padding-right 500ms, color 500ms;
}

#footer[lang='ar'] li:hover {
	padding-right: 0.5rem;
	padding-left: 0;
}

#footer[lang='ar'] .icon-text svg {
	transform: scaleX(-1);
}

#footer[lang='ar'] li.phone-container:hover {
	padding-right: 0;
}

[lang='ar'] .list-group-item:not(:last-of-type) {
	margin-right: 0;
	margin-left: 0.5rem;
}

#footer[lang='ar'] .form-control {
	border-radius: 0.375rem;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

#footer[lang='ar'] .input-group-text {
	border-radius: 0.375rem;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
