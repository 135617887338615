#not-found-page .not-found-btn {
	position: relative;
	overflow: hidden;
	background-color: #dc3545;

	transition: color 400ms;
}

#not-found-page .not-found-btn::after {
	content: '';
	background: #fff;
	opacity: 1;
	position: absolute;
	transition: all 0.4s ease-in;
	z-index: 1;
	height: 100%;
	left: -35%;
	right: unset;
	top: 0;
	transform: skew(30deg);
	transform-origin: top left;
	width: 0;
}

/* Hover */
#not-found-page .not-found-btn:hover::after {
	height: 100%;
	width: 135%;
	opacity: 0;
	background: #dc3545;
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
[lang='ar'] #not-found-page .not-found-btn::after {
	right: -35%;
	left: unset;
	transform: skew(-30deg);
}
