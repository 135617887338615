/* Course Card */
.course-card {
	background-color: #fff !important;
	height: 20rem;
}

.course-card .course-badge {
	font-weight: 600;
	font-size: 12px;
	height: 24px;
	line-height: 24px;
	border-radius: 3px;
	color: #fff;
	position: absolute;
	z-index: 1;
	display: inline-block;
	padding: 0 11px;
}

.course-card .card-thumbnail {
	/* background-color: rgba(var(--primary-color), 0.1);
	background-image: url('./../../assets/images/logos/logo.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain; */

	height: 11rem;

	transform: scale(1);
	transition: transform 0.5s;
}

.course-card:hover .card-thumbnail {
	transform: scale(1.1);
}

.course-card .card-text {
	color: rgba(var(--heading-color), 1);
	transition: color 0.5s;
}

.course-card:hover .card-text {
	color: rgba(var(--main-color), 1) !important;
}

.course-card .card-text:hover {
	color: rgba(var(--primary-color), 1) !important;
}

.course-card-footer::before {
	content: '';
	background-color: #eeeeee;
	width: 90%;
	height: 1px;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
