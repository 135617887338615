/* Root Variables */
:root {
	--primary-color: 185, 75, 77; /* #b94a4b */
	--secondary-color: 52, 48, 90; /* #35305b */
	--main-color: 0, 113, 220; /* #0071dc */
	--border-color: 222, 226, 230; /* #dee2e6 */
	--hover-color: 217, 236, 255; /* #d9ecff */
	--paragraph-color: 128, 129, 145; /* #808191 */
	--heading-color: 17, 20, 45; /* #11142d */
	--table-color: 0, 113, 220; /* #0071dc */
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	font-family: 'Inter', sans-serif;
	box-sizing: border-box;
}

.page {
	--padding-value: 3rem;
	min-height: 100vh !important;
	padding-top: var(--padding-value);
	padding-bottom: var(--padding-value);
}

body {
	overflow-x: hidden;
}

section {
	overflow: hidden;
	background-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0.05),
			rgba(0, 0, 0, 0.1)
		),
		url('./assets/images/background/so-white.png');
}

.text-limit {
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	display: -webkit-box !important;
	-webkit-line-clamp: var(--lines) !important;
	/* number of lines to show */
	line-clamp: var(--lines) !important;
	-webkit-box-orient: vertical !important;
}

.error {
	color: #dc3545;
	font-size: 1.5rem;
	text-align: center;
	text-transform: capitalize;
}

/* General Rules */
.swiper {
	overflow: visible;
}

.swiper-button-next,
.swiper-button-prev {
	width: 48px !important;
	height: 48px !important;
	top: -3rem !important;
	left: unset !important;
	border: 1px solid #e5f1fb !important;
	background-color: #fbfcff !important;
	border-radius: 12px !important;

	transition: background-color 250ms, border-color 250ms;
}

.swiper-button-next {
	right: 0 !important;
}

.swiper-button-prev {
	right: 55px !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
	background-color: #e5f1fb !important;
	border-color: #e5f1fb !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
	font-family: 'FontAwesome' !important;
	font-size: 20px !important;
	font-weight: 700 !important;
	color: #808191 !important;

	transition: color 250ms;
}

.swiper-button-next::after {
	content: '\f105' !important;
}

.swiper-button-prev::after {
	content: '\f104' !important;
}

.form-control.is-invalid {
	border-color: #dc3545;
}

ol,
ul {
	padding: 0;
	margin-top: 32px;
}

ol li,
ul li {
	list-style-type: none !important;
	border: none !important;
	padding: 0 !important;
	margin-bottom: 10px;
	display: flex;
}

ol li::before,
ul li::before {
	/* content: '\f00c';
	font-family: 'FontAwesome' !important;
	color: rgb(0, 113, 220);
	font-size: 20px;
	margin: 0 10px;
	position: relative; */
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
	bottom: -40px !important;
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
[lang='ar'] *,
[lang='ar'] *::before,
[lang='ar'] *::after {
	font-family: 'Cairo', sans-serif;
}

/* General Rules */
[lang='ar'] .swiper-button-next,
[lang='ar'] .swiper-button-prev {
	right: unset !important;
}

[lang='ar'] .swiper-button-next {
	left: 55px !important;
}

[lang='ar'] .swiper-button-prev {
	left: 0 !important;
}

[lang='ar'] .form-select {
	padding: 0.375rem 0.75rem 0.375rem 2.25rem;
	background-position: left 0.75rem center;
}

.paginationRow {
    .paginationCol {
        display: flex;
        justify-content: center;
        align-items: center;

        .page-item {
            .page-link {
                color: rgb(var(--main-color));

                &:focus {
                    box-shadow: 0 0 0 0.25rem rgba(rgba(var(--main-color),0.25));
                }
            }

            &.active {
                >.page-link {
                    background-color: rgb(var(--main-color)) !important;
                    border-color: rgb(var(--main-color)) !important;
                    color: #fff !important;
                }
            }
        }

        &:lang(ar) {
            .page-item {
                &:first-child {
                    .page-link {
                        border-top-left-radius: unset !important;
                        border-bottom-left-radius: unset !important;
                        border-top-right-radius: 0.375rem !important;
                        border-bottom-right-radius: 0.375rem !important;
                    }
                }

                &:last-child {
                    .page-link {
                        border-top-right-radius: unset !important;
                        border-bottom-right-radius: unset !important;
                        border-top-left-radius: 0.375rem !important;
                        border-bottom-left-radius: 0.375rem !important;
                    }

                }
            }
        }
    }

}
