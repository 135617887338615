#course-request-page .action-btns {
	position: fixed;
	bottom: 30px;
	color: #ffffff;
	z-index: 1050;
	font-size: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 40px;
}

@media only screen and (max-width: 992px) {
	#course-request-page .action-btn {
		right: 24px;
	}
}

.form-control.location-name {
	width: unset;
	flex: 1;
}

.form-title {
	position: relative;
	color: rgba(var(--primary-color), 1);
	width: fit-content;
}

.form-title::after {
	content: '';
	background-color: rgba(var(--primary-color), 1);
	height: 2px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.rmdp-input {
	display: block;
	width: 100%;
	height: unset !important;
	padding: 0.375rem 0.75rem !important;
	margin: 0 !important;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	appearance: none;
	border-radius: 0.375rem !important;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.icon-tabler-calendar-event {
	right: 0.75rem !important;
	stroke: #212529 !important;
}

.rmdp-header {
	background-color: #689decc4;
}

.rmdp-arrow {
	border-color: #ffffff;
	margin: 0;
}

.rmdp-right i {
	margin-right: 2px;
}

.rmdp-left i {
	margin-left: 2px;
}

.rmdp-arrow-container {
	align-items: center;
}

.rmdp-arrow-container:hover {
	background-color: #ffffff;
}

.rmdp-arrow-container:hover .rmdp-arrow {
	border-color: #689dec;
}

.rmdp-header-values {
	color: #ffffff;
}

/* DatePicker Day Names */
.rmdp-week-day {
	color: hwb(216 41% 7%);
	font-weight: 600;
}

/* DatePicker Day Numbers */
.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden):not(.rmdp-selected)
	span:hover {
	background-color: rgba(185, 74, 75, 0.6);
}

.rmdp-day.rmdp-selected span:not(.highlight),
.rmdp-day.rmdp-selected span:hover {
	background-color: #689decc4 !important;
}

.rmdp-day.rmdp-today span {
	background-color: unset;
	color: #000000;
}

#course-request-page .PhoneInput {
	border: 1px solid #eeeeee;

	transition: border-color 300ms;
}

#course-request-page .form-control.is-invalid,
#course-request-page .rmdp-input.is-invalid,
.PhoneInput.is-invalid,
.PhoneInput *.is-invalid {
	border-color: #dc3545 !important;
	border-radius: 0.375rem;
}

#course-request-page .filter-option {
	color: rgba(var(--heading-color), 1);
}

#course-request-page .filter-option * {
	cursor: pointer;
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
[lang='ar'] .icon-tabler-calendar-event {
	left: 0.75rem !important;
	right: unset !important;
}

#course-request-page[lang='ar'] .action-btns {
	left: 40px;
	right: unset;
}

@media only screen and (max-width: 992px) {
	#course-request-page[lang='ar'] .action-btns {
		left: 24px;
		right: unset;
	}
}
