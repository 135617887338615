.navbar {
	--bs-navbar-padding-y: 0 !important;
	--bs-navbar-padding-x: 0 !important;
}

.navbar-collapse.collapse {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

@media only screen and (max-width: 992px) {
	.navbar-collapse.collapse {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
}

/* Navbar Brand */
.navbar-brand {
	margin-right: 2rem !important;
	margin-left: 0 !important;
}

@media only screen and (max-width: 576px) {
	.navbar-brand {
		width: 50%;
		margin-right: 1rem !important;
	}

	.navbar-brand img {
		max-height: 4rem;
		object-fit: contain;
		object-position: left;
	}
}

@media only screen and (min-width: 576px) {
	.navbar-brand {
		width: 50%;
	}

	.navbar-brand img {
		max-height: 4rem;
		object-fit: contain;
		object-position: left;
	}

	[lang='ar'] .navbar-brand img {
		object-position: right;
	}
}

@media only screen and (min-width: 992px) {
	.navbar-brand {
		width: fit-content;
		margin-right: 0.5rem !important;
	}

	.navbar-brand img {
		max-height: 2.5rem;
	}
}

@media only screen and (min-width: 1200px) {
	.navbar-brand {
		width: fit-content;
	}

	.navbar-brand img {
		max-height: 4rem;
	}
}

/* Toggle Icon */
.navbar-toggler {
	color: rgba(var(--heading-color), 1) !important;
	background-color: rgba(var(--primary-color), 1) !important;
	--bs-navbar-toggler-border-color: rgba(var(--heading-color), 1) !important;
}

.navbar-toggler.collapsed {
	--bs-navbar-toggler-border-color: rgba(var(--primary-color), 1) !important;
	color: rgba(var(--primary-color), 1) !important;
	background-color: transparent !important;
}

.navbar-toggler:focus {
	box-shadow: none !important;
}

/* Nav Link */
.navbar-nav .nav-link:last-child {
	padding-left: var(--bs-navbar-nav-link-padding-x) !important;
}

@media only screen and (max-width: 992px) {
	.navbar-nav .nav-link:last-child {
		padding-left: 0 !important;
	}
}

.navbar-nav .nav-link.signup-btn {
	padding-left: var(--bs-navbar-nav-link-padding-x) !important;
	padding-right: 0 !important;
}

@media only screen and (max-width: 992px) {
	.navbar-nav .nav-link.signup-btn {
		padding-left: 0 !important;
	}
}

.nav-link,
.navbar-text {
	font-size: 1rem !important;
	align-self: center !important;
}

@media only screen and (min-width: 992px) {
	.nav-link,
	.navbar-text {
		font-size: 0.9rem !important;
	}
}

@media only screen and (min-width: 1200px) {
	.nav-link,
	.navbar-text {
		font-size: 1rem !important;
	}
}

@media only screen and (min-width: 1400px) {
	.nav-link,
	.navbar-text {
		font-size: 1rem !important;
	}
}

@media only screen and (max-width: 992px) {
	.nav-link,
	.navbar-text {
		font-size: 1rem !important;
		align-self: unset !important;
	}
}

.nav-link:hover {
	--bs-nav-link-hover-color: rgba(var(--primary-color), 1) !important;
}

.nav-link.active {
	--bs-navbar-active-color: rgba(var(--primary-color), 1) !important;
}

/* Dropdown */
.dropdown {
	align-self: center !important;
}

@media only screen and (max-width: 992px) {
	.dropdown {
		align-self: unset !important;
	}
}

.dropdown-toggle[aria-expanded='true'] {
	--bs-navbar-active-color: rgba(var(--primary-color), 1) !important;
}

.dropdown-toggle::after {
	display: none !important;
}

.dropdown-menu {
	--bs-dropdown-border-radius: 0 !important;
	--bs-dropdown-padding-y: 0 !important;
	--bs-dropdown-border-width: 0 !important;
	box-shadow: 0 0 4px 1px rgba(var(--paragraph-color), 1);
}

@media only screen and (max-width: 991px) {
	.dropdown-menu {
		box-shadow: none;
	}
}

/* Translation Button */
.navbar-nav #translation {
	transition: color 250ms;
}

.navbar-nav #translation:hover {
	color: rgba(var(--main-color), 1);
}

#training-courses-nav-dropdown + .dropdown-menu {
	--bs-dropdown-min-width: 20rem !important;
}

#request-course-nav-dropdown + .dropdown-menu {
	--bs-dropdown-min-width: 15rem !important;
}

#services-nav-dropdown + .dropdown-menu {
	--bs-dropdown-min-width: 12rem !important;
}

.dropdown-item {
	text-align: start !important;
	--bs-dropdown-item-padding-y: 10px !important;
	--bs-dropdown-link-color: var(--bs-navbar-color) !important;
	transition: padding-left 500ms, background-color 250ms, color 250ms !important;
	transition-timing-function: ease !important;
}

.dropdown-item:not(:last-of-type) {
	border-bottom: 1px solid #f5f5f5 !important;
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active,
.dropdown-item.active {
	--bs-dropdown-link-hover-bg: rgba(var(--primary-color), 1) !important;
	--bs-dropdown-link-active-bg: rgba(var(--primary-color), 1) !important;
	color: #ffffff !important;
	padding-left: 1.5rem !important;
}

.dropdown-item:active {
	--bs-dropdown-link-active-bg: rgba(var(--primary-color), 1) !important;
}

.navbar .navbar-text svg {
	width: 25px !important;
}

/* Search Icon */
.navbar #search-icon {
	color: rgba(var(--main-color), 1);
	background-color: #f7f8fc;
	border-radius: 8px;
	padding: 8px 12px;

	transition: color 500ms;
}

.navbar #search-icon:hover {
	color: rgba(var(--primary-color), 1);
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
[lang='ar'] .navbar-brand {
	margin-right: 0 !important;
	margin-left: 2rem !important;
}

[lang='ar'] .navbar-nav .nav-link:last-child {
	padding-right: var(--bs-navbar-nav-link-padding-x) !important;
}

@media only screen and (max-width: 992px) {
	[lang='ar'] .navbar-nav .nav-link:last-child {
		padding-right: 0 !important;
	}
}

[lang='ar'] .navbar-nav .nav-link.signup-btn {
	padding-right: var(--bs-navbar-nav-link-padding-x) !important;
	padding-left: 0 !important;
}

@media only screen and (max-width: 992px) {
	[lang='ar'] .navbar-nav .nav-link.signup-btn {
		padding-right: 0 !important;
	}
}

[lang='ar'] #training-courses-nav-dropdown + .dropdown-menu {
	--bs-dropdown-min-width: 14rem !important;
}

[lang='ar'] #request-course-nav-dropdown + .dropdown-menu {
	--bs-dropdown-min-width: 11rem !important;
}

[lang='ar'] #services-nav-dropdown + .dropdown-menu {
	--bs-dropdown-min-width: 12rem !important;
}

[lang='ar'] .dropdown-item {
	transition: padding-right 500ms, background-color 250ms, color 250ms !important;
}

[lang='ar'] .dropdown-item:hover,
[lang='ar'] .dropdown-item:focus {
	padding-right: 1.5rem !important;
	padding-left: var(--bs-dropdown-item-padding-x) !important;
}
