#search-result-page .badge {
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent !important;
	color: rgba(var(--paragraph-color), 1) !important;
	border: 1px solid rgba(var(--heading-color), 1) !important;
	margin: 8px 8px 0;
	text-transform: capitalize;
}

#search-result-page .badge.accordion-badge {
	margin: 0 16px;
	color: #ffffff !important;
	border-color: #ffffff !important;
	background-color: rgba(var(--main-color), 1) !important;
}

#search-result-page .group-title {
	display: block;
	color: rgba(var(--heading-color), 1);
	font-size: 20px;
	font-weight: 700;
	padding-bottom: 18px;
}

#search-result-page .filter-option {
	color: rgba(var(--heading-color), 1);
	min-width: 50%;
	text-transform: capitalize;
}

.form-check-inline {
	margin-right: 0;
}

#search-result-page .search-filter-container {
	margin-top: 1rem;
}

#search-result-page .search-filter-container:first-of-type {
	margin-top: 0;
}

#search-result-page .search-filter-head {
	background-color: rgba(var(--primary-color), 1);
	color: #ffffff;
	padding: 0.5rem 0.5rem;
	border-radius: 0.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

input[type='checkbox'],
input[type='checkbox'] + label {
	cursor: pointer;
}

input[type='checkbox'] {
	border: 1px solid rgba(var(--heading-color), 1);
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
#search-result-page[lang='ar'] .form-select {
	padding: 0.375rem 0.75rem 0.375rem 2.25rem;
	background-position: left 0.75rem center;
}
