.feature-card img {
	filter: grayscale(35%);

	transition: filter 350ms;
}

.feature-card:hover img,
.feature-card:focus img {
	filter: grayscale(0);
}

.feature-card .card-title {
	color: rgba(var(--heading-color), 1);

	transition: color 350ms;
}

.feature-card:hover .card-title,
.feature-card:focus .card-title {
	color: rgba(var(--main-color), 1);
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
