#loading-component {
	background-color: rgba(255, 255, 255, 1);
	width: 100%;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999;
}

#loading-component > .row {
	height: 100%;
}

#loading-component img {
	max-height: 24rem;
	user-select: none;
	pointer-events: none;
}

@media only screen and (max-width: 992px) {
	#loading-component img {
		max-width: 75%;
	}
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
