.contact-info-component ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.contact-info-component li::before {
	display: none !important;
}

.contact-info-component li:hover {
	color: rgba(var(--primary-color), 1);
	padding-left: 0.5rem;
}

.contact-info-component a {
	color: #343439;
	text-decoration: none;

	transition: color 250ms;
}

.contact-info-component a:hover {
	color: rgba(var(--primary-color), 1);
}

.contact-info-component .phone-number {
	direction: ltr !important;
}

.contact-info-component #phone-numbers a:not(:last-of-type) .phone-number {
	padding-bottom: 0.5rem;
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
[lang='ar'] .contact-info-component .icon-text svg {
	transform: scaleX(-1);
}
