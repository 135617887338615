/* Buttons */
.btn-base {
	color: #ffffff;
	background: rgba(var(--main-color), 1);
}

.btn-base-light {
	color: rgba(var(--main-color), 1);
	background: #e5f1fb;
}

.btn {
	--bs-btn-font-weight: 600;
	--bs-btn-padding-x: 1rem !important;
	--bs-btn-padding-y: 0.5rem !important;
	position: relative;
	overflow: hidden;
	border: 0 !important;

	transition: color 400ms;
}

/* Button After */
.btn-base-light::after {
	background: #fff;
	opacity: 1;
}

.btn-base-light::after {
	background: #fff;
	opacity: 0;
}

.btn::after {
	content: '';
	background: rgba(var(--main-color), 1);
	position: absolute;
	transition: all 0.4s ease-in;
	z-index: 1;
	height: 100%;
	left: -35%;
	right: unset;
	top: 0;
	transform: skew(30deg);
	transform-origin: top left;
	width: 0;
}

#search-result .btn::after {
	background: #e5f1fb;
}

.btn.disabled {
	background-color: rgba(var(--paragraph-color), 1);
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
[lang='ar'] .btn::after {
	right: -35%;
	left: unset;
	transform: skew(-30deg);
}

/* Button Hover */
.btn:hover {
	--bs-btn-hover-bg: #e5f1fb;
}

.btn-base:hover {
	--bs-btn-hover-bg: rgba(var(--main-color), 1);
}

.btn-base:hover::after {
	background: #e5f1fb;
	opacity: 0;
}

.btn-base-light:hover::after {
	background: rgba(var(--main-color), 1);
	opacity: 1;
}

.btn:hover::after {
	height: 100%;
	width: 135%;
}
