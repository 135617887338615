#about-us-page .about-card {
	box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
	overflow: hidden;
	border-radius: 0.5rem;
}

#about-us-page .about-card-description {
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#about-us-page .about-card-description > p {
	padding: 0;
	margin: 0;
}

/* About Page Lists */
#about-us-page .available-sections ol,
#about-us-page .available-sections ul {
	padding: 0;
	margin: 0;
}

#about-us-page .available-sections ol li,
#about-us-page .available-sections ul li {
	list-style-type: none !important;
	border: none !important;
	padding: 0 !important;
	margin-bottom: 10px;
	color: rgba(0, 0, 0, 0.5) !important;
	display: flex;
}

#about-us-page .available-sections ol li::before,
#about-us-page .available-sections ul li::before {
	content: '\f00c';
	font-family: 'FontAwesome';
	color: rgb(0, 113, 220);
	font-size: 20px;
	margin: 0 10px;
	position: relative;
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
