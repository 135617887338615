#form-title {
	text-transform: capitalize;
	color: #ffffff;
	width: 50%;
	text-align: center;
}

.group-title {
	color: #ffffff !important;
	margin: 0;
}

.main-search-form-component select,
.main-search-form-component .form-control {
	position: relative;
	display: block;
	background-color: transparent;
	color: #ffffff;
	border-width: 0;
	border-bottom-width: 1px;
	border-radius: 0;
	cursor: pointer;
}

.main-search-form-component select:focus,
.main-search-form-component .form-control:focus {
	box-shadow: none;
}

.main-search-form-component select {
	background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
}

.main-search-form-component .form-control::placeholder {
	color: #ffffff;
}

.main-search-form-component .form-select:disabled {
	background-color: transparent;
	cursor: not-allowed;
}

#search-form > .row > * {
	padding: 0;
}

#search-form > .row .form-group::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);
	z-index: 10;
	height: 2px;
	width: 0;
	background-color: rgba(var(--primary-color), 1);
	transition: width 500ms;
}

#search-form > .row .form-group:has(*:focus)::before {
	width: 100%;
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
