.custom-btn-container .btn,
.custom-btn-container .btn:active,
.custom-btn-container .btn:focus {
    background-color: rgba(var(--primary-color), 1);

}

.custom-btn-container .btn::after {
	/* background: rgba(var(--primary-color), 1); */
    background: rgba(255, 255, 255, 0.2);
}
/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
