.back-to-top {
	cursor: pointer;
	position: fixed;
	bottom: 30px;
	color: #ffffff;
	z-index: 1050;
	font-size: 20px;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0 5px rgb(0 0 0 / 10%);
}

.back-to-top {
	right: 50px;
	width: 44px;
	height: 44px;
	background-color: rgba(var(--main-color), 1);
	border-radius: 4px;

	animation: back-to-top-bounce 4s infinite ease-in-out;
}

@media only screen and (max-width: 992px) {
	.back-to-top {
		right: 24px;
	}
}

@keyframes back-to-top-bounce {
	0% {
		-webkit-transform: translateY(-4px);
		transform: translateY(-5px);
	}

	50% {
		-webkit-transform: translateY(8px);
		transform: translateY(10px);
	}

	100% {
		-webkit-transform: translateY(-4px);
		transform: translateY(-5px);
	}
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
[lang='ar'] .back-to-top {
	left: 50px;
	right: unset;
}

@media only screen and (max-width: 992px) {
	[lang='ar'] .back-to-top {
		left: 24px;
		right: unset;
	}
}
