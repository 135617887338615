.plan-card {
	border: none !important;
	box-shadow: 0px 6px 15px rgb(0 0 0 / 5%);
}

/* Plan Image */
.plan-card img {
	filter: grayscale(100%);

	transition: filter 500ms;
}

.plan-card:hover img,
.plan-card:focus img {
	filter: grayscale(0);
}

.plan-card .card-body {
	color: rgba(var(--paragraph-color), 1);

	transition: color 500ms;
}

.plan-card:hover .card-body {
	color: rgba(var(--heading-color), 1);
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
