#certificate-check-modal #request-certificate span {
	cursor: pointer;
	width: fit-content !important;

	transition: color 350ms;
}

#certificate-check-modal #request-certificate span:hover {
	color: rgba(var(--primary-color), 1) !important;
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
