#breadcrumb {
	position: relative;
	background-color: #f9fafd;
	border-bottom: 1px solid #e5f1fb;
	color: #ffffff;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)),
		var(--bg-image), url('./../../assets/images/logos/logo.png');
	background-position: center, 0 25%, center;
	background-repeat: no-repeat;
	background-size: cover, cover, contain;
	mix-blend-mode: multiply;
}

@media only screen and (max-width: 1400px) {
	#breadcrumb {
		background-position: center;
	}
}

.breadcrumb-item {
	color: #aeaeae;
	text-decoration: none;
	font-size: 16px;
	transition: color 250ms;
}

.breadcrumb-item:hover,
.breadcrumb-item.active {
	color: #ffffff !important;
}

.breadcrumb-item.active {
	cursor: default;
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
[lang='ar'] .breadcrumb-item + .breadcrumb-item::before {
	float: right;
	padding-left: var(--bs-breadcrumb-item-padding-x);
}
