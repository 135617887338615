.whatsapp-chat {
	cursor: pointer;
	position: fixed;
	bottom: 30px;
	color: #ffffff;
	z-index: 1050;
	font-size: 20px;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0 5px rgb(0 0 0 / 10%);
}

.whatsapp-chat {
	left: 50px;
	width: 55px;
	height: 55px;
	background-color: #54cc61;
	border-radius: 50%;
	padding: 5px;
}

@media only screen and (max-width: 992px) {
	.whatsapp-chat {
		left: 24px;
	}
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
[lang='ar'] .whatsapp-chat {
	right: 50px;
	left: unset;
}

@media only screen and (max-width: 992px) {
	[lang='ar'] .whatsapp-chat {
		right: 24px;
		left: unset;
	}
}
