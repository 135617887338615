.table tr th,
.table tr td {
	white-space: nowrap;
	vertical-align: middle;
}

.table .course-title * {
	color: rgba(var(--heading-color), 1) !important;
	transition: color 250ms;
}

.table .course-title *:hover {
	color: rgba(var(--main-color), 1) !important;
}

.table th {
	position: sticky;
	top: 0;
	z-index: 99;
	--bs-table-bg: rgba(var(--secondary-color), 1) !important;
}

.table td {
	color: rgba(var(--heading-color), 1) !important;
	font-size: 14px;

	transition: color 250ms;
}

.table tr:hover td,
.table tr:hover .course-title * {
	color: #0d6efd !important;
}

.table-striped {
	--bs-table-striped-bg: rgba(var(--table-color), 0.1) !important;
	--bs-table-hover-bg: rgba(var(--table-color), 0.2) !important;
}

.table-hover > tbody > tr:hover > *,
.table tr:hover .course-title * {
	--bs-table-hover-color: #ffffff !important;
}

.table .btn {
	background: rgba(var(--primary-color), 1);
	font-size: 14px;
}

.table .btn::after {
	background: #e5f1fb;
}

.table .btn-base:hover {
	--bs-btn-hover-bg: rgba(var(--primary-color), 1);
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
