#course-details-page .course-preview {
	background-color: rgba(var(--primary-color), 0.1);
	background-image: url('./../../assets/images/logos/logo.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

#course-details-page .nav-item {
	max-width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

#course-details-page .nav-tabs .nav-link {
	text-transform: capitalize;
	font-size: 18px !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#course-details-page .nav-tabs li::before {
	display: none !important;
}

#course-details-page .tab-content {
	border: 1px solid #e5f1fb;
	background-color: #f9fafd;
	padding: 30px 30px;
	border-radius: 0.5rem;
	margin-top: 1rem;
}

/* Course Details Page Lists */
#course-details-page .tab-content ol,
#course-details-page .tab-content ul {
	padding: 0;
	margin-top: 32px;
}

#course-details-page .tab-content ol li,
#course-details-page .tab-content ul li {
	list-style-type: none !important;
	border: none !important;
	padding: 0 !important;
	margin-bottom: 10px;
	display: flex;
}

#course-details-page .tab-content ol li::before,
#course-details-page .tab-content ul li::before {
	content: '\f00c';
	font-family: 'FontAwesome';
	color: rgb(0, 113, 220);
	font-size: 20px;
	margin: 0 10px;
	position: relative;
}

#course-details-page .card {
	background-color: transparent;
}

#course-details-page .card-header {
	color: rgba(var(--heading-color), 1);
	background: transparent;
	margin-bottom: 0;
	padding: 18px 1rem 21px;
	font-size: 20px;
	font-weight: 600;
}

#course-details-page .course-badge {
	font-weight: 600;
	font-size: 12px;
	height: 24px;
	line-height: 24px;
	border-radius: 3px;
	color: #fff;
	display: inline-block;
	padding: 0 11px;
}

#course-details-page .card-text {
	display: flex;
	font-size: 16px;
	font-weight: 500;
}

#course-details-page .detail-title {
	min-width: 25%;
	text-transform: capitalize;
	color: rgba(var(--heading-color), 1) !important;
}

#course-details-page .swiper {
	overflow: hidden;
}

@media only screen and (min-width: 991px) {
	#course-details-page .card-text {
		font-size: 15px;
	}

	#course-details-page .detail-title {
		min-width: 35%;
	}
}

@media only screen and (min-width: 1200px) {
	#course-details-page .card-text {
		font-size: 16px;
	}
}

@media only screen and (min-width: 1400px) {
	#course-details-page .detail-title {
		min-width: 30%;
	}
}

/* Search Items */
#course-details-page .search-items .card {
	background-color: #f9fafd;
	border: 1px solid #e5f1fb;
}

#course-details-page .search-items .card-header {
	padding: 0.5rem 1rem;
	background-color: rgba(var(--primary-color), 1);
	color: #ffffff;
}

#course-details-page .search-items .card-text {
	position: relative;
	color: rgba(var(--paragraph-color), 1);
	padding: 0;
	padding-left: 1rem;
	transition-property: color, padding;
	transition-duration: 500ms;
}

#course-details-page .search-items .card-text::before {
	content: '\f101';
	font-family: 'FontAwesome';
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
	transition-property: left, right;
	transition-duration: 500ms;
}

#course-details-page .search-items .card-text:hover {
	cursor: pointer;
	padding-right: 0;
	padding-left: 1.5rem;
	color: rgba(var(--primary-color), 1);
}

#course-details-page .search-items .card-text:hover::before {
	left: 0;
}
#course-details-page .course-details .card-text {
	padding: 0 !important;
	cursor: default !important;
}

#course-details-page .course-details .card-text::before {
	display: none;
}

#course-details-page .share-btn {
	color: #689dec;
	display: flex;
	justify-content: center;
	align-items: center;

	transition: color 350ms;
}

#course-details-page .share-btn:hover,
#course-details-page .share-btn:focus {
	color: rgba(var(--main-color), 1);
	cursor: pointer;
}

#course-details-page .nav-tabs .nav-link,
#course-details-page .nav-tabs .nav-item.show .nav-link,
#course-details-page .nav-tabs .nav-link.active {
	border: none;
	font-weight: 600;
	font-size: 24px;
	color: rgba(var(--heading-color), 1);
	background: transparent;
	border-bottom: 4px solid transparent;
	border-radius: 0;
	padding: 0 19px 30px;
	--bs-nav-tabs-link-active-color: rgba(var(--heading-color), 1);
	--bs-nav-tabs-link-active-color: rgba(var(--heading-color), 1);
}

#course-details-page .nav-tabs .nav-link.active {
	border-bottom: 4px solid rgba(var(--main-color), 1);
}

.modal-body .react-share__ShareButton:not(:last-of-type) {
	margin-right: 0.8rem !important;
}

.modal-body .share-icon-title {
	font-size: 1rem;
	font-weight: 400;
	color: rgba(var(--paragraph-color), 1);

	transition: color 350ms;
}

.modal-body .react-share__ShareButton:hover .share-icon-title {
	color: rgba(var(--heading-color), 1);
}

.form-control:focus {
	box-shadow: none;
}

/* ******************************************************************************** */
/* **********************************   RTL   ************************************* */
/* ******************************************************************************** */
[lang='ar'] #course-details-page .search-items .card-text {
	padding: 0;
	padding-right: 1rem;
}

[lang='ar'] #course-details-page .search-items .card-text:hover {
	padding-left: 0;
	padding-right: 1.5rem;
}

[lang='ar'] #course-details-page .search-items .card-text::before {
	content: '\f100';
	left: unset;
	right: 0;
}

[lang='ar'] #course-details-page .search-items .card-text:hover::before {
	right: 0;
}
